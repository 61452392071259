import { ComponentProps, For, Match, onCleanup, Switch } from "solid-js";
import * as pdfjs from "pdfjs-dist";
import workerSrc from "pdfjs-dist/build/pdf.worker.min.mjs?url";

import createAsync from "@repo/signals/createAsync";
import Range from "@repo/utils/Range";
import USolid from "@repo/utils-solid/USolid";

import Spinner from "../loaders/Spinner";
import createDomRect from "../signals/createDomRect";

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

declare namespace Pdf {
  type Props = ComponentProps<"div"> & {
    url: string;
    withCredentials?: boolean;
  };
}

function Pdf({ url, withCredentials, ...props }: D<Pdf.Props>) {
  const pdf = createAsync(() => {
    const { destroy, promise } = pdfjs.getDocument({
      url,
      withCredentials,
    });
    onCleanup(destroy);
    return promise;
  });

  return (
    <Switch>
      <Match when={pdf.loading()}>
        <Spinner.Common />
      </Match>
      <Match when={pdf.error()}>
        <div class="color:error">
          Error when loading PDF:{" "}
          {
            // Cast to string
            `${pdf.error()}`
          }
        </div>
      </Match>
      <Match when={pdf()}>
        {(pdf) => {
          const rect = createDomRect();

          return (
            <div {...props} ref={rect.ref}>
              <For each={Range(pdf().numPages)}>
                {(n) => {
                  return (
                    // Reactively destroy and recreate canvases when container width changes
                    <>
                      {(() => {
                        if (!USolid.runMemo(() => rect()?.width)) return;
                        return (
                          <canvas
                            ref={async (canvas) => {
                              const page = await pdf().getPage(n + 1);

                              const viewport = page.getViewport({ scale: 1 });
                              // Support HiDPI-screens.
                              const outputScale = window.devicePixelRatio || 1;
                              // Fit to container
                              const containerScale =
                                USolid.runMemo(() => rect()!.width) /
                                viewport.width;
                              const scale = outputScale * containerScale;

                              canvas.width = Math.floor(viewport.width * scale);
                              canvas.height = Math.floor(
                                viewport.height * scale
                              );
                              canvas.style.width =
                                Math.floor(viewport.width * containerScale) +
                                "px";
                              canvas.style.height =
                                Math.floor(viewport.height * containerScale) +
                                "px";

                              page.render({
                                canvasContext: canvas.getContext("2d")!,
                                viewport,
                                transform:
                                  scale !== 1
                                    ? [scale, 0, 0, scale, 0, 0]
                                    : undefined,
                              });
                            }}
                          />
                        );
                      })()}
                    </>
                  );
                }}
              </For>
            </div>
          );
        }}
      </Match>
    </Switch>
  );
}

export default Pdf;
