import {
  createMemo,
  createSignal,
  For,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { useParams } from "@solidjs/router";
import c from "class-c";

import Expand from "@repo/ui/animation/Expand";
import Button from "@repo/ui/buttons/Button";
import LoadableButton from "@repo/ui/buttons/LoadableButton";
import ScrollView from "@repo/ui/containers/ScrollView";
import Line from "@repo/ui/decoration/Line";
import AttachmentViewer from "@repo/ui/files/AttachmentViewer";
import Detail from "@repo/ui/info/Detail";
import ListTile from "@repo/ui/info/ListTile";
import WithKeybind from "@repo/ui/keybind/WithKeybind";
import PageLayout from "@repo/ui/layout/PageLayout";
import Spinner from "@repo/ui/loaders/Spinner";
import Link from "@repo/ui/navigation/Link";
import Address from "@repo/utils/Address";
import FullName from "@repo/utils/FullName";
import UDate from "@repo/utils/UDate";
import UString from "@repo/utils/UString";
import AppRouter from "@repo/utils-solid/AppRouter";

import { useOverlaysController } from "~/overlays";
import { useUsers } from "~/state/features/users";

import styles from "./[userId].module.scss";

export default function ManualIdvPage() {
  const params = useParams<{ userId: string }>();

  const overlays = useOverlaysController();

  const [users, setUsers] = useUsers();
  const [pending, setPending] = createSignal(false);

  const pendingManualIdvAttempt = createMemo(() =>
    users
      .pendingManualIdvAttempts()
      ?.find((attempt) => attempt.userId === params.userId)
  );

  return (
    <div class={styles.page}>
      <nav class={styles.scroll}>
        <div class="text-center py-1 text:small">
          Total count: {users.pendingManualIdvAttempts()?.length}
        </div>
        <div class="center-child">
          <Button
            variant="text-primary"
            size="full-width"
            onClick={() => setPending(!pending())}
          >
            Show {pending() ? "Action" : "Pending"} Cases
          </Button>
        </div>
        <For
          each={users
            .pendingManualIdvAttempts()
            ?.filter((attempt) => attempt.pending == pending())}
        >
          {(pendingManualIdvAttempt) => (
            <Link
              href={`/manual-idv/${pendingManualIdvAttempt.userId}`}
              class={c`${pendingManualIdvAttempt.userId === params.userId && styles.current}`}
            >
              <ListTile
                class="p-2 m-0"
                propsFor={{
                  title: {
                    class: "text:regular+",
                  },
                  subtitle: {
                    class: "text:mini",
                  },
                }}
                title={FullName.format(pendingManualIdvAttempt.name)}
                subtitle={pendingManualIdvAttempt.userId}
              />
              <Line />
            </Link>
          )}
        </For>
      </nav>
      <main class="column">
        <Switch>
          <Match when={!users.pendingManualIdvAttempts()}>
            <Spinner.Common />
          </Match>
          <Match when={!pendingManualIdvAttempt()}>
            {(() => {
              onMount(() => {
                AppRouter.replace(
                  UString.withAllValues`/manual-idv/${users.pendingManualIdvAttempts()![0]?.userId}` ||
                    "/manual-idv"
                );
              });

              return <Spinner.Common />;
            })()}
          </Match>
        </Switch>
        <Show when={pendingManualIdvAttempt()}>
          {(pendingManualIdvAttempt) => {
            return (
              <>
                <ScrollView class="expand">
                  <PageLayout
                    title={FullName.format(pendingManualIdvAttempt().name)}
                    subtitle={
                      <Link
                        href={`/users/${pendingManualIdvAttempt().userId}`}
                        target="_blank"
                      >
                        {pendingManualIdvAttempt().userId}
                      </Link>
                    }
                  >
                    <Expand
                      show={pendingManualIdvAttempt().pending}
                      direction="y"
                    >
                      <div class={c`color:on-warning p-1 ${styles["pending"]}`}>
                        Pending
                      </div>
                    </Expand>
                    <Detail.PropsProvider variant="stacked">
                      <Detail label="Full name">
                        {FullName.format(pendingManualIdvAttempt().name)}
                      </Detail>
                      <Detail label="Address">
                        {Address.format(pendingManualIdvAttempt().address)}
                      </Detail>
                      <Detail label="Birth date">
                        {pendingManualIdvAttempt().birthDate}
                      </Detail>
                      <Detail label="Submitted at">
                        {UDate.format(
                          pendingManualIdvAttempt().uploadedAt,
                          "mm/dd/yyyy"
                        )}
                      </Detail>
                    </Detail.PropsProvider>
                    <div class={styles.attachments}>
                      <div>
                        <header class="text:large+">Photo ID</header>
                        <AttachmentViewer
                          attachment={pendingManualIdvAttempt().photoId}
                        />
                      </div>
                      <div>
                        <header class="text:large+">
                          Selfie with photo ID
                        </header>
                        <AttachmentViewer
                          attachment={pendingManualIdvAttempt().selfie}
                        />
                      </div>
                    </div>
                  </PageLayout>
                </ScrollView>
                <Line />
                <footer class="row p-3 pb-4">
                  <div class="expand" />
                  <WithKeybind alt key="A">
                    <LoadableButton
                      as={Button}
                      variant="secondary"
                      size="standard"
                      onClick={() => {
                        return setUsers.setManualPendingIdvAttempt(
                          pendingManualIdvAttempt().id
                        );
                      }}
                    >
                      Mark case as{" "}
                      {pendingManualIdvAttempt().pending
                        ? "not pending"
                        : "pending"}
                    </LoadableButton>
                  </WithKeybind>
                  <WithKeybind alt key="F">
                    <LoadableButton
                      as={Button}
                      variant="discouraged"
                      size="standard"
                      onClick={async () => {
                        const failureReason = await overlays.push(
                          "manual-idv/FailureReasonPrompt"
                        ).result;
                        if (!failureReason) throw null;
                        return setUsers.reviewManualIdvAttempt(
                          pendingManualIdvAttempt().userId,
                          "FAILED",
                          failureReason
                        );
                      }}
                    >
                      Fail
                    </LoadableButton>
                  </WithKeybind>
                  <WithKeybind alt key="R">
                    <LoadableButton
                      as={Button}
                      variant="secondary"
                      size="standard"
                      onClick={async () => {
                        const failureReason = await overlays.push(
                          "manual-idv/FailureReasonPrompt"
                        ).result;
                        if (!failureReason) throw null;
                        return setUsers.reviewManualIdvAttempt(
                          pendingManualIdvAttempt().userId,
                          "NEEDS_REUPLOAD",
                          failureReason
                        );
                      }}
                    >
                      Request reupload
                    </LoadableButton>
                  </WithKeybind>
                  <WithKeybind alt key="P">
                    <LoadableButton
                      as={Button}
                      variant="primary-soft"
                      size="standard"
                      onClick={() => {
                        return setUsers.reviewManualIdvAttempt(
                          pendingManualIdvAttempt().userId,
                          "PASSED"
                        );
                      }}
                    >
                      Pass
                    </LoadableButton>
                  </WithKeybind>
                </footer>
              </>
            );
          }}
        </Show>
      </main>
    </div>
  );
}
